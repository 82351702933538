.search {
  margin-top: 30px;
  text-align: center;
}

div.results.transition {
  margin-left: auto !important;
  margin-right: auto !important;
  right: 0 !important;
}
