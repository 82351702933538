.imageContainer {
  border-radius: 0px !important;
}

.icon {
  max-height: 200px;
}

.borderless {
  border-width: 0px;
}
