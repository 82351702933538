.App-header {
  background-color: #1b1c1d;
}

.App-header img {
  max-width: 750px;
  max-height: 300px;
  display: block;
  margin: auto;
}
