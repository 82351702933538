body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: url("images/bkgd-table.png") !important;
  background-repeat: repeat;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}

@media screen {
  .no-screen,
  .no-screen * {
    display: none !important;
  }
}

.autoWidth input {
  width: auto !important;
}
