body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: url(/static/media/bkgd-table.0c127133.png) !important;
  background-repeat: repeat;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}

@media screen {
  .no-screen,
  .no-screen * {
    display: none !important;
  }
}

.autoWidth input {
  width: auto !important;
}

.App-header {
  background-color: #1b1c1d;
}

.App-header img {
  max-width: 750px;
  max-height: 300px;
  display: block;
  margin: auto;
}

.dropdownMenuItem {
  color: black !important;
}

.contentContainer {
  padding-top: 30px;
}

.imageContainer {
  border-radius: 0px !important;
}

.icon {
  max-height: 200px;
}

.borderless {
  border-width: 0px;
}

.search {
  margin-top: 30px;
  text-align: center;
}

div.results.transition {
  margin-left: auto !important;
  margin-right: auto !important;
  right: 0 !important;
}

pre.bhajanLyrics {
  font-family: "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  font-size: 1.5em;
  line-height: 1.3;
}

.bhajanLyricsContainer {
  background-color: rgba(233, 205, 144, 0.5);
  padding: 30px;
}

.contentContainerWrapper {
  background-color: rgba(233, 205, 144, 0.5);
  padding: 30px;
}

.RichEditor-root {
  font-family: 'Georgia', serif;
  font-size: 14px;
  padding: 15px;
}

.RichEditor-root.readwrite {
  background: #fff;
  border: 1px solid #ddd;
}

.RichEditor-editor {
  background: #fff3;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
  padding-left: 8px;
  padding-right: 8px;
}

.RichEditor-editor.readwrite {
  border-top: 1px solid #ddd;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.RichEditor-styleButton {
  background: #777;
  color: #ccc;
  cursor: pointer;
  margin-right: 4px;
  padding: 4px 10px;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #ffaa00;
}

