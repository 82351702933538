pre.bhajanLyrics {
  font-family: "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  font-size: 1.5em;
  line-height: 1.3;
}

.bhajanLyricsContainer {
  background-color: rgba(233, 205, 144, 0.5);
  padding: 30px;
}
